<template>
    <div class="modal secondary new_exercise active" v-if="modelValue">
        <div class="modal_container">
            <h2>
               Update Exercise
                <button type="button" class="favorite_btn" @click="form.is_favourite = form.is_favourite ? 0 : 1"><i :class="form.is_favourite ? 'fas fa-heart' : 'far fa-heart'"></i></button>
                <button type="button" class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
            </h2>
            <div class="setting_wpr w-100">
                <Form @submit="handleSubmitForm" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" ref="new-exercise-form">
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Exercise Name</label>
                            <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                <Field autocomplete="off" type="text" name="name" v-model="form.name" placeholder="ex: New Exercise" rules="required" />
                            </div>
                            <ErrorMessage name="name" class="text-danger" />
                        </div>
                    </div>
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Exercise Description</label>
                            <div class="field_wpr">
                                <textarea v-model="form.description" name="description" cols="30" rows="10" placeholder="Description goes here.."></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Exercise Thumb</label>
                            <image-library v-model="form.thumb" image-type="exercise-thumb" upload-text="Image" :is-avatar="false" :crop="true" />
                        </div>
                    </div>
                    <h3 class="sub_header mt-2 mb-2">Video Link</h3>
                    <div class="edit_section mb-2">
                        <label for="video_url" class="switch_option capsule_btn p-0 mt-0 mb-2 border-0">
                            <h5 class="large mb-0">Video URL</h5>
                            <input type="radio" name="exercise_toggle" id="video_url" :value="1" v-model="form.video_type" hidden>
                            <div><span></span></div>
                        </label>
                        <div class="setting_wpr" v-if="form.video_type == 1 && !form.has_gif">
                            <div class="form_grp p-0">
                                <div class="group_item">
                                    <div class="field_wpr" :class="{ 'has-error': errors.video_link }">
                                        <Field autocomplete="off" type="text" name="video_link" v-model="form.video_link" placeholder="ex: New Exercise" rules="required|url" label="video link" />
                                    </div>
                                    <ErrorMessage name="video_link" class="text-danger" />
                                </div>
                            </div>
                        </div>
                        <label for="embed_video" class="switch_option capsule_btn p-0 mt-4 mb-0 border-0">
                            <h5 class="large m-0">Embeded Video</h5>
                            <input type="radio" name="exercise_toggle" id="embed_video" :value="2" v-model="form.video_type" hidden>
                            <div><span></span></div>
                        </label>
                        <div class="setting_wpr" v-if="form.video_type == 2 && !form.has_gif">
                            <div class="form_grp py-2">
                                <div class="group_item">
                                    <div class="field_wpr" :class="{ 'has-error': errors.embed_code }">
                                        <Field type="textarea" name="embed_code" v-model="form.embed_code" placeholder="ex: New Exercise" rules="required" label="embed code">
                                            <textarea v-model="form.embed_code" name="embed_code" cols="10" rows="30" placeholder="embeded code here.."></textarea>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="embed_code" class="text-danger" />
                                </div>
                            </div>
                        </div>
                        <label for="gif_image" class="switch_option capsule_btn p-0 mt-4 mb-2 border-0">
                            <h5 class="large mb-0">GIF Image</h5>
                            <input type="radio" name="exercise_toggle" id="gif_image" :value="1" v-model="form.has_gif" hidden>
                            <div><span></span></div>
                        </label>
                        <div class="setting_wpr" v-if="form.has_gif">
                            <image-library v-model="form.gif_image" image-type="exercise-gif" upload-text="GIF Image" :is-avatar="false" :crop="false" :gif-only="true" />
                        </div>
                    </div>
                    <div class="attribute_area">
                        <h4>
                            <label>Attributes</label>
                            <a v-if="selectedFilterTags.length" @click="selectedFilterTags = [];">Clear Attributes</a>
                            <a @click="addAttributes = !addAttributes;">{{addAttributes ? 'Close' : 'Add'}} Attributes</a>
                        </h4>
                        <ul class="att_tags">
                            <li v-for="(tag, t) in selectedFilterTags" :key="t" :class="tag.type">
                                {{ tag.name }}
                                <span class="delete_btn" @click="selectedFilterTags.splice(t, 1)"><i class="fas fa-times"></i></span>
                            </li>
                        </ul>
                    </div>
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Instructions</label>
                            <div class="field_wpr">
                                <textarea name="instructions" v-model="form.instructions" cols="30" rows="10" placeholder="Instruction goes here.."></textarea>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
            <div class="action_wpr mt-4">
                <button :disabled="loader" type="button" class="btn cancel_btn mx-1" @click="closeModal()">Cancel</button>
                <button :disabled="loader" type="button" class="btn save_btn" @click="handleSubmitForm"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'updating' : 'update' }}</button>
            </div>
        </div>
    </div>
    <workout-attribute v-model="addAttributes" title="Attribute List" :selected-attibutes="form.attributes" :toggle-attribute-select="handleSelectExerciseAttribute" :is-fixed="true" />
</template>
<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ImageLibrary = defineAsyncComponent(() => import('@/components/image-library/ImageLibrary'))
    const WorkoutAttribute = defineAsyncComponent(() => import('@/pages/workout/components/WorkoutAttribute'))

    export default {
        name: 'New Workout Exercise',

        data () {
            return {
                form: {
                    name: '',
                    description: '',
                    video_type: 1,
                    video_link: '',
                    embed_code: '',
                    attributes: [],
                    instructions: '',
                    is_favourite: 0,
                    has_gif: 0,
                    gif_image: '',
                    thumb: '',
                },
                loader: false,
                addAttributes: false,
                selectedFilterTags: [],
            }
        },

        props: {
            modelValue: Boolean,
            selectedExercise: Object,
            refreshAsset: {
                type: Function,
                default: () => {}
            }
        },

        emit: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            WorkoutAttribute,
            ImageLibrary,
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.resetForm();
                }
            },

            selectedFilterTags: {
                handler (exerciseAttributes) {
                    const vm = this;

                    vm.form.attributes = exerciseAttributes.map((attribute) => attribute.id);
                },
                deep: true,
            },

            'form.has_gif' (val) {
                const vm = this;

                if (val) {
                    vm.form.video_type = 0;
                } else {
                    vm.form.video_type = 1;
                }
            },

            'form.video_type' (val) {
                const vm = this;

                if (val) {
                    vm.form.has_gif = 0;
                }
            },
        },

        computed: {
            ...mapState({
                exerciseAttributes: state => state.workoutModule.exerciseAttributes,
            }),
        },

        methods: {
            ...mapActions({
                updateWorkoutExercise: 'workoutModule/updateWorkoutExercise',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSubmitForm () {
                const vm   = this;
                const form = vm.$refs['new-exercise-form'];

                form.validate().then((result) => {
                    if (result.valid) {
                        vm.form.setFieldError = form.setFieldError;
                        vm.loader             = true;
                        vm.updateWorkoutExercise(vm.form).then((result) => {
                            vm.loader = false;

                            if (result) {
                                vm.refreshAsset();
                                vm.closeModal();
                            }
                        });
                    }
                });


            },

            resetForm () {
                const vm = this;

                vm.form = JSON.parse(JSON.stringify(vm.selectedExercise));

                vm.selectedAttibutes  = vm.form.attributes ? JSON.parse(JSON.stringify(vm.form.attributes)) : [];

                vm.selectedFilterTags = [];

                if (vm.selectedAttibutes && vm.selectedAttibutes.length) {
                    vm.selectedAttibutes.forEach((attributeId) => {
                        const attribute = vm.exerciseAttributes.find(({id}) => id == attributeId);

                        if (attribute) {
                            vm.selectedFilterTags.push(attribute);
                        }
                    });
                }

                vm.addAttributes      = false;
            },

            handleSelectExerciseAttribute (attributeId) {
                const vm        = this;
                const attribute = vm.exerciseAttributes.find(({id}) => id == attributeId);

                if (attribute) {
                    vm.handleSelectFilterTag (attribute);
                }
            },

            handleSelectFilterTag (attribute) {
                const vm = this;

                const hasFilter = vm.selectedFilterTags.findIndex((att) => att.id === attribute.id);

                if (hasFilter == -1) {
                    vm.selectedFilterTags.push(attribute);
                } else {
                    vm.selectedFilterTags.splice(hasFilter, 1);
                }
            },
        }
    }
</script>

<style scoped>
    .modal.new_exercise .modal_container{
        padding: 20px 30px 0 30px;
        max-height: 600px;
        overflow-y: scroll;
    }
    .modal.new_exercise .modal_container::-webkit-scrollbar{
        display: none;
    }
    .modal.new_exercise h2{
        margin-bottom: 5px;
        font-size: 22px;
        line-height: 28px;
        position: relative;
        width: 100%;
        padding: 0 20px;
        text-align: center;
    }
    .modal.new_exercise h2 .favorite_btn{
        position: absolute;
        left: 0;
        top: 5px;
        cursor: pointer;
        font-size: 16px;
        color: #777;
    }
    .modal.new_exercise h2 .close_btn{
        position: absolute;
        right: 0;
        top: 5px;
        cursor: pointer;
        font-size: 16px;
        color: #777;
    }
    .attribute_area{
        padding: 15px 0 10px 0;
    }
    .attribute_area h4{
        display: flex;
        align-items: center;
        padding-bottom: 6px;
        gap: 10px;
    }
    .attribute_area h4 label{
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: #121525;
        margin-right: auto;
    }
    .attribute_area h4 a{
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #5a5a5a;
        cursor: pointer;
    }
    .attribute_area .att_tags{
        border: 1px solid #CBCDD3;
        border-radius: 6px;
        padding: 10px 15px;
        justify-content: flex-start;
        min-height: 50px;
    }
    .attribute_bar.fixed{
        position: fixed;
        right: -230px;
        top: 0;
        bottom: 0;
        z-index: 13;
        transition: all 0.3s ease-in-out;
    }
    .attribute_bar.fixed.show{
        right: 0;
    }
    .new_exercise .action_wpr{
        width: calc(100% + 60px);
        border-top: 1px solid #e9e9e9;
        background: #fff;
        padding: 20px 25px;
        margin: 0 -30px;
        position: sticky;
        bottom: 0;
    }
    .attribute_bar.fixed.show{
        right: 0;
    }
    .new_exercise .action_wpr{
        width: calc(100% + 60px);
        border-top: 1px solid #e9e9e9;
        background: #fff;
        padding: 20px 25px;
        margin: 0 -30px;
        position: sticky;
        bottom: 0;
    }

    .att_tags{
    /* background: #fff; */
    border-radius: 6px;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
    }

    .att_tags li{
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 4px 8px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        position: relative;
    }
    .att_tags .delete_btn{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        background: #eb1414;
        color: #fff;
        position: absolute;
        right: -5px;
        top: -5px;
        cursor: pointer;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    .att_tags li:hover .delete_btn{
        opacity: 1;
    }
    .att_tags li.exercise{
        border-color: #88d3d9;
    }
    .att_tags li.position{
        border-color: #b99191;
    }
    .att_tags li.contraction{
        border-color: #968bca;
    }
    .att_tags li.muscle{
        border-color: #76afff;
    }
    .att_tags li.movement{
        border-color: #cb72ff;
    }
    .att_tags li.equipment{
        border-color: #56e094;
    }
    .att_tags li.skeletal{
        border-color: #fb76c8;
    }
    .att_tags li.difficulty{
        border-color: #f2a31d;
    }
</style>
